import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IThemeState, themeNames } from './themeSlice.contracts';
import { HeaderFooterTextLifeWise } from 'src/constants/wording/LifeWiseWording';
import { HeaderFooterTextChubb } from 'src/constants/wording/ChubbWording';
import { HeaderFooterTextMix } from 'src/constants/wording/MixTelematicsWording';
import { HeaderFooterTextLiberty } from 'src/constants/wording/LibertyWording';

// TODO: get theme from storage, which should be set on app init (check where oggleTheme is called now and call it in App.js)


const initialState: IThemeState = {
  theme: localStorage.getItem('appTheme') || themeNames.dark,

  footerWording: HeaderFooterTextLifeWise
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<{ theme: themeNames }>) {
      const { theme } = action.payload;
      localStorage.setItem('appTheme', theme);
      switch (theme) {
        case themeNames.lifeWise:
          state.footerWording = HeaderFooterTextLifeWise;
          break;
        case themeNames.chubb:
          state.footerWording = HeaderFooterTextChubb;
          break;
        case themeNames.mix:
          state.footerWording = HeaderFooterTextMix;
          break;
        case themeNames.liberty:
          state.footerWording = HeaderFooterTextLiberty;
          break;
      }
      state.theme = theme;
    },
    toggleTheme(state, action) {
      if (state.theme === themeNames.dark) state.theme = themeNames.light;
      else state.theme = themeNames.dark;
    },
    setFooterWording(state, action) {
      if (state.theme === themeNames.dark) state.theme = themeNames.light;
      else state.theme = themeNames.dark;
    },
  },
});

export default themeSlice.reducer;
export const { setTheme, toggleTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme.theme;

export const selectFooterWording = (state: RootState) => state.theme.footerWording;