import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import router from 'src/router/router';
import ThemeProvider from './theme/ThemeProvider';

function App() {
    const content = useRoutes(router);
   
    return (
        <ThemeProvider>
            <CssBaseline />
            <ToastContainer />
            {content}
        </ThemeProvider>
    );
}

export default App;
