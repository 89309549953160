import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';
import { selectTheme } from '../../store/theme/themeSlice';
import { themeNames } from '../../store/theme/themeSlice.contracts';

interface ILogoInterface {
  height?: number | string;
  width?: number | string;
}

function Logo(props: ILogoInterface) {
  let { height = '70px', width = 'auto' } = props;

  const theme = useTheme();
  const curThemeName = useAppSelector(selectTheme);

  // TODO: change icon dynamically based on variable
  let imageSrc;
  let center = false;
  switch (curThemeName) {
    case themeNames.hollard:
      imageSrc = '/static/images/brand/hollard-logo-1.svg'
      break;
    case themeNames.lifeWise:
      imageSrc = '/static/images/brand/lifewise-logo.svg'
      break;
    case themeNames.dark:
      imageSrc = '/static/images/brand/Octoco Logo 8.svg'
      break;
    case themeNames.light:
      imageSrc = '/static/images/brand/Octoco Logo 1.svg'
      break;
    case themeNames.chubb:
      height = '20px';
      width = 'auto';
      imageSrc = '/static/images/brand/CHUBB_Logo_Black_RBG.png';
      break;
    case themeNames.mix:
      imageSrc = '/static/images/brand/icon-matrixlogo.svg';
      break;
    case themeNames.liberty:
      imageSrc = '/static/images/brand/liberty-logo.png';
      width = '100%';
      center = true;
      break;
  }

  if (center) {
    return (
      <Box justifyContent='center' display='flex'>
        <img src={imageSrc} alt='Logo' height={height} width={width} />
      </Box>
    );
  } else {
    return (
      <Box>
        <img src={imageSrc} alt='Logo' height={height} width={width} />
      </Box>
    );
  }

}

export default Logo;
