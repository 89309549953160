import { Box, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/hooks';
import { themeNames } from 'src/store/theme/themeSlice.contracts';
import { selectTheme } from '../../store/theme/themeSlice';

import Footer from './Footer';
import Header from './Header';

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = () => {
  const theme = useTheme();
  const curThemeName = useAppSelector(selectTheme);

   let sx = {};

  switch (curThemeName) {
    case themeNames.mix:
      sx = {
        display: 'inherit',
        flexDirection: 'column',
        // height: 'auto',
        // minHeight: '100%',
        backgroundImage: 'url("/static/images/background/mix.png")', // Replace with your image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
      break;
    default:
      sx = {
        display: 'inherit',
        flexDirection: 'column',
        background: theme.colors.alpha.white[100]
      };
  }


  return (
    
      <Box sx={sx}>
        {/* <Sidebar /> */}
        <Header />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
        //     background: theme.colors.alpha.white[100],
        //     backgroundImage: 'url("/static/images/background/mix.png")', // Replace with your image path
        // backgroundSize: 'cover',
        // backgroundPosition: 'center'
          }}
        >
          <Box display='block' >
            <Outlet />
          </Box>
        </Box>
        <Footer />
      </Box>
    
  );
};

export default Layout;
