
import {
  alpha, Box, styled,
  useTheme
} from '@mui/material';
import { useAppSelector } from 'src/hooks/hooks';
import { selectFooterWording } from 'src/store/theme/themeSlice';


const FooterWrapper = styled(Box)(
  ({ theme }) => `
  height: max-content;
  color: ${theme.footer.textColor};
  padding: ${theme.spacing(0, 2)};
  background-color: ${theme.footer.background};
  backdrop-filter: blur(3px);
  justify-content: space-between;
  width: 100%;
  
`,
);

function Footer() {
  const theme = useTheme();
  const footerWording = useAppSelector(selectFooterWording);

  return (
    <FooterWrapper
      display='flex'
      alignItems='center'
    >
      <Box display='flex' flexDirection='column' sx={footerWording?.copyRightText ??
      {
        width: '100%',
      }}>
        {footerWording.copyrightText && <Box
          component='span'
          sx={{
            p: '3%',
            pb: '1%'
          }}
        >
          {footerWording.copyrightText}
        </Box>}
        {footerWording?.footerText && <Box
          component='span'
          sx={footerWording?.copyRightText ? {
            p: '3%',
            pt: '1%',
            pb: '3%',
          } : {
            p: '3%',
            pb: '3%',
            width: '100%',
            textAlign: 'center'
          }}
        >
          {footerWording?.footerText}
        </Box>}
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
