export enum themeNames {
  dark = 'NebulaFighterTheme',
  light = 'PureLightTheme',

  hollard = 'HollardTheme',

  lifeWise = 'LifeWiseTheme',

  chubb = 'ChubbTheme',

  mix = 'MixTelematicsTheme',

  liberty = 'LibertyTheme'
}

export interface IThemeState {
  theme: themeNames | string;

  footerWording: any
}
