export const HeaderFooterTextLifeWise = {
    footerText: 'LifeWise, a trading brand of Family Insurance (Pty) Ltd (Reg No 1998/012373/07) a Microinsurer licensed to conduct life microinsurance business and an authorised Financial Services Provider (FSP No 15959). Policies are underwritten by Hollard Life Assurance Company Ltd (Reg No 1993/001405/06) a registered life insurer and FSP.',
    copyrightText: null
}

export const StepsLifeWise = {
    minStep: 1,
    maxStep: 12,
    Preamble: {
        name: 'Preamble',
        title: 'Thank you for choosing LifeWise!',
        text: 'Start Your Digital Sign-Up. Complete our quick online process and get covered immediately',
        inputTC: {
            required: false,
            type: 'checkbox',
            label: 'I consent to receiving marketing information from LifeWise and the LEZA Group for related product services',
            helperText: 'Hollard and LifeWise are committed to protecting your privacy. By providing your personal information, you consent to your information being collected in order to gain access to our products and services. Your information will be used properly, lawfully, securely and transparently for the purpose for which it is intended, namely, the administration and further maintenance of your insurance product/s'
        },
        inputMarketing: {
            required: true,
            type: 'checkbox',
            label: 'You confirm that the consent provided to us, is given on behalf of yourself, your minor dependents or any other person to be added to your membership, where you aknowledge and warrant that you have their permission to give such consent. You are also consenting that Hollard and LifeWise may use your information to contact you regarding changes or updates about your insurance product/s and that LifeWise may use your information in imporving our product offering.'
        },
        slider: [
            { title: 'R30,000 funeral cover.', descriptionArr: ['Up to <b>5 (five)</b> children can also be covered on one policy.', 'Children between the age of 14 and 21 are covered for 100% of the plan cover.', 'Children between the age of 6 and 13 are covered for 50% of the plan cover.', 'Children between the age of 1 and 5 are covered to a maximum of R10 000.', 'Children under the age of 1 year, including a child stillborn following 28 weeks of pregnancy and not as a result of a wilful abortion are covered for R1 000.', 'A 6 month waiting period applies for natural causes.'] },
            { title: 'Double payout for accidental death', descriptionArr: ['LifeWise will pay out double the cover (R60 000) in the case of an accidental death of either the member or partner.','This double cover starts immediately and lasts as long as the life of your policy.', '48-hour or less payout times for approved claims.']  }
        ]
    },
    Qualify: {
        name: 'Qualify',
        title: 'Excellent!​ Let’s start by getting to know you a bit better.',
        inputName: {
            type: 'text',
            label: 'What is your full first name as in your South African ID document?',
            placeholder: 'First Name​'
        },
        inputSurname: {
            type: 'text',
            label: 'What is your last name as in your South African ID document?',
            placeholder: 'Surname​'
        },
        inputPhone: {
            type: 'text',
            label: 'What is your phone number?',
            placeholder: 'Phone Number'
        },

    },
    PersonalDetails: {
        name: 'PersonalDetails',
        title: 'Nice to meet you',
        hasNames: true,
        text:  'We are required by law to get your ID number to provide you with insurance services. Your details are safe and private with us.',
        inputIDNumber: {
            label: 'What is your ID number?',
            placeholder: 'ID number.​'
        },
        inputEmail: {
            label: 'Please enter the e-mail address to which we can send your membership agreement.',
            placeholder: 'Email​'
        },
        alternatePhone: true

    },
    Summary: {
        name: 'Summary',
        benefits: {
            text: 'Required in terms of the Financial Advisory and Intermediary Services Act, 37 of 2002 (FAIS Act) \
            \nImportant - Please read carefully (this notice does not form part of the insurance contract or any other document). As a long term insurance policyholder, or prospective policyholder, you have the right to the following information: \
            \n1.	About the Intermediary (Binder Holder): \
            \na.	Full business name, full trade name, registration number, physical address, postal address, and telephone number: \
            \nLifeWise, a trading brand of Family Insurance (Pty) Ltd (Reg No 1998/012373/07) a Microinsurer licensed to conduct life microinsurance business and an authorised Financial Services Provider (FSP No 15959). Policies are underwritten by Hollard Life Assurance Company Ltd (Reg No 1993/001405/06) a registered life insurer and FSP. Physical Address: Somerset Office Estate, 604 Kudu Street, Allen\'s Nek, Roodeport 1737. Postal Address: PO Box 3294, Florida, 1710.  Tel: 011 472 2020. \
            \nYou will be informed of any material changes to the information above.\
            \n b.	Legal status and any interest in the Insurer: \
            \nLifeWise acts as a Binder Holder who provides Intermediary and Administrator services on behalf of Hollard Life Assurance Company Limited and holds no interest. \
            \nc.	Professional Indemnity Insurance: \
            \nLifeWise has professional indemnity cover in place. \
            \n d.	Shareholdings in insurer if 10% or more: \
            \nNo shareholdings.\
            \ne.	Name of insurer from which the Binder holder received 30% or more of total remuneration, including commission, during the preceding 12 month period: \
            \nHollard Life Assurance Company Limited. \
            \nf.	The nature and extent of commission which may become payable by LifeWise: \
            \nIndependent contractors earn an once-off acquisition fee (maximum of R200-00) for every policy application completed, if a policy is subsequently issued by the Insurer. Independent contractors acting on our behalf are authorised to only introduce and provide factual information about the product/s. Note that Independent Contractors are not authorised to: \
            \n(i)	Give financial advice, \
            \n(ii)	Perform an affordability assessment, and \
            \n(iii)	Compare any LifeWise products to any other products in order to recommend suitable insurance cover. \
            \ng.	Complaints resolution procedure: \
            \nShould you wish to lodge a complaint regarding the service rendered to you, you may address your complaint to LifeWise: \
            \n-	in writing by email to: customercare@staylifewise.co.za; or \
            \n-	in writing to our postal address indicated in 1 (a) above. \
            \nPlease refer to the Information Section of the LifeWise Policy document which sets out the procedure to follow for different types of complaints you wish to report. Your complaint should provide full details of the service of which you are complaining. A copy of all relevant documentation should accompany your complaint. If your complaint is not resolved to your satisfaction, you may proceed to lodge a complaint with the FAIS Ombudsman or the Ombudsman for Long-Term Insurance, whichever is applicable. \
            \nThe FAIS Ombudsman is an independent and impartial dispute resolution tribunal which investigates, considers and disposes of complaints by consumers against Financial Services Providers. For example, the way a policy was sold or how a service was provided. Contact details in this regard can be found under point 4(l). \
            \nThe Ombudsman for Long-Term Insurance provides consumers with a free dispute resolution mechanism. It mediates between subscribing members, such as the Insurer/ intermediary/ binder holder, and policyholders regarding insurance contracts and is available to advise you in the event of claim problems which are not satisfactorily resolved by such subscribing members. The procedure for lodging a complaint can be found on the website of the Ombudsman for Long-Term Insurance (refer to point 4 (j) below for contact details). \
            \nh.	Contact details of the compliance function: \
            \nThe FAIS Compliance Officer deals with issues relating to compliance with the FAIS Act and complaints related to how this sale was conducted. \
            \nContact details: Physical address: Constantia Ridge Office Park, Block B, 231 Panorama Drive, Constantia Kloof 1709. Postal Address: PO Box 6144, Weltevreden Park 1715. Tel: 011 534 8701.                                             Email: faiscompliance@leza.co.za\
            \ni.	Conflict of Interest Policy: \
            \nIn line with the requirements of the FAIS Act, LifeWise has adopted a policy to avoid and mitigate any potential conflicts of interest. The conflict of interest policy is available at www.staylifewise.co.za or from the FAIS Compliance Officer. \
            \n2.	About the Insurer: \
            \na.	Full business name, full trade name, registration number, physical address, postal address, and telephone number: \
            \nThe Hollard Life Assurance Company Limited (Registration Number: 1993/001405/06). Physical address: 22 Oxford Road, Parktown, 2193. Postal address: PO Box 87428, Houghton, 2041. Tel: 011 351 5000. \
            \nb.	Type of policy: \
            \nThe LifeWise funeral policy is a Category 1 life insurance product.\
            \nc.	Contact details of the Hollard compliance function: \
            \nThe Compliance Officer. Tel: 011 351 8035.\
            \nd.	Binder Disclosure: \
            \nThe binder holder, LifeWise, has entered into an agreement with Hollard Life Assurance Company Limited, a Licensed Life Insurer and Authorised Financial Services Provider, which authorises it to enter into, vary or renew an insurance policy, collect premiums and settle claims. \
            \nFor performing the abovementioned functions, the binder holder is paid a maximum fee of 20% of gross written premium.\
            \n3.	The Binder Holder, Administrator, Intermediary or Insurer dealing with you must inform you of:\
            \n3.1	The premium that you will be paying. \
            \n3.2	The nature and extent of benefits that you will receive. \
            \n3.3	Concise details of any special terms and conditions, exclusions, waiting periods, restrictions, or circumstances in which benefits will not be provided. \
            \n3.4	Premiums and periods of grace.\
            \na.	Premiums are due and payable monthly.\
            \nb.	A period of 30 days grace is allowed for the payment of each premium. During this time, all benefits will remain in force. If any event occurs during the period of grace that results in a claim, the unpaid premium will be deducted from any amount paid out. \
            \nc.	Should there be no further payments received after the 30 day grace period the policy will lapse and all cover will be terminated. \
            \n3.5	Your rights when being advised to replace an existing policy. \
            \nYou may not be advised to cancel a policy to enable you to purchase a new policy or amend an existing policy, unless the following information is fully disclosed to you: \
            \na.	The actual and potential financial implications, costs and consequences of such replacement, \
            \nb.	Details of any special terms and conditions, exclusions, waiting periods, restrictions, or circumstances in which benefits will not be provided. \
            \n4.	Other matters of importance: \
            \na.	Hollard and LifeWise and its employees and Independent Contractors are committed to protecting your privacy. By signing the application form, you consent to your information being collected by LifeWise in order to gain access to our products and services. Your information will be used properly, lawfully, securely and transparently for the purpose for which it is intended, namely, the administration and further maintenance of your insurance product/s. Hollard and LifeWise have implemented appropriate technical and organisational information security measures to help keep your information secure, accurate and current. You are also consenting that Hollard and LifeWise may use your information to contact you regarding changes or updates about your insurance product/s and that LifeWise may use your information in improving our product offering. If you do not want to receive any future product or service offerings from LifeWise, then inform us by contacting Member Administration on 011 271 4079. You confirm that the consent provided to us, is given on behalf of yourself, your minor dependents or any other Person to be added to your policy, where you acknowledge and warrant that you have their permission to give such consent. \
            \nb.	You remain responsible for the accuracy and completeness of all answers / information provided by you. \
            \nc.	You are requested not to sign any blank or partially completed document. All documents must be completed in ink. \
            \nd.	It is important that you, as a policyholder, are aware of your premium obligations. The due date of premiums and the consequences of non-payment of premiums are important. This information is indicated in the policy document. \
            \ne.	Note that no person or provider may request or induce you in any manner to waive any right or benefit conferred on you in terms of any provision contained in the FAIS Code of Conduct. \
            \nf.	You are entitled to a full copy of the LifeWise Policy Document within 31 days of LifeWise issuing the policy. If you did not receive a copy, please contact us without delay.\
            \ng.	You will be provided with written notification of any claim that is rejected or any decision taken in respect of any value in dispute. Should you disagree with the rejection of the claim / decision taken in respect of value, you may make representations to the insurer, within 90 days of receipt of the rejection letter. \
            \nYou, your beneficiary or executor may also approach the Ombudsman for Long Term Insurance. If you, your beneficiary or executor are still not happy with the decision, a summons may be served on Hollard not more than 270 days after you have received the rejection letter. \
            \nh.	LifeWise will not cancel your policy without first giving you 31 days written notice of its intention. Any variations to your existing LifeWise Policy Document will be communicated to you in writing. \
            \ni.	You have 31 days from date of receipt of the policy to peruse the policy terms and conditions. Should you feel that the policy is not suitable for your requirements, you may cancel same in writing within 31 days from date of receipt, where after any premiums paid will be refunded to you. Please bear in mind that you may not exercise this right if you have already claimed under the policy or if the event, which the policy insures you against, has already happened. \
            \nj.	The Long Term Insurance Ombudsman can be contacted at: \
            \nPrivate Bag X45, Claremont, 7735. Tel: 021 657 5000, Fax: 021 674 0951, Email: info@ombud.co.za\
            \nk.	The Registrar of Long-Term Insurance can be contacted at:\
            \nFinancial Services Conduct Authority (FSCA), P O Box 35655, Menlo Park, 0102. Tel: 012 428 8000, Fax: 012 346 6941\
            \nl.	The FAIS Ombudsman can be contacted at: \
            \nP.O. Box 74571, Lynwood Ridge, 0040, Tel: 012 470 9080 / 012 762 5000, Fax: 012 348 3447/086 764 1422, E-mail: info@faisombud.co.za, \
            \nWebsite: www.faisombud.co.za \
            \nm.This Statutory disclosure notice has been issued for information purposes only.\
            \nFor the complete terms and conditions please refer to the LifeWise Policy Document.',
            title: 'Statutory Disclosure Notice to Policyholders'
        },
        inputContinue: {
            label: 'By clicking on the Continue button, you have confirmed that you read our Statutory Disclosure Notice, which contains important information relating to our cover.',
            errorMessage: 'Please confirm that you read our Statutory Disclosure Notice',
            required: true
        }
    },
    BankDetails: {
        name: 'BankDetails',
        title: 'Confirmation',
        text: 'Super. Now all we need are your banking details and you will be covered!',
        inputDebitDate: {
            label: 'Debit Order Date'
        },
    },
    OTPStep1: {
        name: 'OTPStep1',
        title: 'Almost there',
        text: 'You hereby authorise LifeWise, a trading brand of Family Insurance (Pty) Ltd, a microinsurer licensed to conduct life microinsurance business and authorised FSP (15959), as a non-mandated intermediary of Hollard Life Assurance Company Limited (Hollard) a registered life insurer and FSP (17697), to arrange a monthly premium deduction(s) and or deduction(s) of arrear(s) by means of a Debit Order from your nominated bank account. You acknowledge that all payment instructions issued by LifeWise or Hollard shall be treated by your abovementioned bank as if the instruction has been issued by you personally. You may not delegate your obligations in terms of this authority to a third party without prior written consent from LifeWise or Hollard. You acknowledge that Hollard may not cede or assign any of its rights to a third party other than LifeWise without your written consent. You also acknowledge that if the deduction is unsuccessful to meet the obligation, LifeWise or Hollard is entitled to track your account with DebiCheck or any other similar system and re-present the instruction for payment monthly on alternative dates when the obligation in terms of the LifeWise membership Agreement is due. You agree that this mandate and authority will continue until cancelled by you, by giving LifeWise thirty days written notice. You also understand that you cannot reclaim amounts that have been withdrawn from your bank account (paid) in terms of this mandate and authority if such amounts were legally owing by you. To conclude the acceptance of your LifeWise Funeral Policy and the authorisation of your monthly premium payment, we require your authorisation by means of you capturing a One Time Password (OTP) within this digital joining process. We will send an OTP in a SMS to your mobile device. Rates of your cell phone provider will apply to the SMS. '
    },
    OTPStep2: {
        name: 'OTPStep2',
        title: 'Enter OTP:',
        text: 'LifeWise Funeral Insurance Policy'
    },
    ThankYou: {
        name: 'ThankYou',
        title: 'Welcome to the Chubb family!',
        email: 'memberadmin@staylifewise.co.za',
        tcLink: 'https://www.staylifewise.co.za',
        additions: 'add a partner and 5 children'
    },
    Beneficiary: {
        title: 'Beneficiary',
        min: 1,
        max: 1
    }
}