export const HeaderFooterTextLiberty = {
    footerText: 'Liberty Group Limited - a Licensed Life Insurer and an Authorised Financial Services Provider (Licence no 2409)',
    copyrightText: null
}

export const StepsLiberty = {
    minStep: 1,
    maxStep: 12,
    Preamble: {
        name: 'Preamble',
        title: 'Thank you for choosing Liberty!',
        text: 'We believe that you should have the freedom to choose the type of cover and benefits that suit your needs, as your life changes. Your funeral plan should offer you the flexibility to choose benefits that best suit your budget and your needs.',
        inputMarketing: {
            required: true,
            type: 'checkbox',
            label: 'You confirm that the consent provided to us, is given on behalf of yourself, your minor dependents or any other Person to be added to your Membership, where you acknowledge and warrant that you have their permission to give such consent. You are also consenting that Liberty may use your information to contact you regarding changes or updates about your insurance product/s and that Liberty may use your information in improving our product offering.'
        }
    },
    Qualify: {
        name: 'Qualify',
        title: 'Excellent!​ Let’s get you covered',
        inputName: {
            type: 'text',
            label: 'Full First Name',
            placeholder: ''
        },
        inputSurname: {
            type: 'text',
            label: 'Last Name',
            placeholder: ''
        },
        inputPhone: {
            type: 'text',
            label: 'Phone Number',
            placeholder: ''
        },

    },
    PersonalDetails: {
        name: 'PersonalDetails',
        title: 'Nice to meet you',
        hasNames: true,
        text: 'We are required by law to get your ID number to provide you with insurance services. Rest assured your information is protected.',
        inputIDNumber: {
            label: 'What is your ID number?',
            placeholder: 'ID number.​'
        },
        inputEmail: {
            label: 'Please enter the e-mail address to which we can send your membership agreement.',
            placeholder: 'Email Adress​'
        }
    },
    CoverSelect: {
        name: 'CoverSelect',
        title: 'Take your pick',
        text: 'Choose from our three options',
        slider: [
            { title: 'Basic cover', premium: 'R50', descriptionArr: ['Cover up to R100 000'] },
            { title: 'Comprehensive cover', premium: 'R100', descriptionArr: ['Cover up to R100 000', 'Tombstone', 'Double Accident', 'Inkomo Cash'] },
            { title: 'Full Cover + Benefits', premium: 'R200', descriptionArr: ['Cover up to R100 000', 'Tombstone', 'Double Accident', 'Inkomo Cash', 'Cash Back', 'Paid Up', 'Assistance to help with airtime', 'Assistance to help with groceries','Assistance to help with transport', 'Assistance to help with other expenses'] }
        ]
    },
    Summary: {
        name: 'Summary',
        // inputContinue: {
        //     label: 'By clicking on the Continue button, you have confirmed that you read our Statutory Disclosure Notice, which contains important information relating to our cover.',
        //     errorMessage: 'Please confirm that you read our Statutory Disclosure Notice',
        //     required: true
        // }
    },
    BankDetails: {
        name: 'BankDetails',
        title: 'Confirmation',
        subTitle: 'You’re almost there',
        text: 'Now all we need are your banking details and payment, and you will be covered!',
        inputDebitDate: {
            label: 'Debit Order Date'
        },
        accountNumber: {
            label: 'Account Number'
        }
    },
    OTPStep2: {
        name: 'OTPStep2',
        title: 'Enter OTP:',
        text: 'Liberty Funeral Insurance Policy',
        textHide: true
    },
    ThankYou: {
        title: 'Thank You',
        subTitle: 'You’re covered',
        text: 'You have successfully selected Product 1. Way to go. You’re monthly payment will be deducted from your account details provided.',
        subText: 'If we do not collect your monthly contribution, we may debit you on another date using a bank tracking facility. If you miss a payment, we may debit you the following month. Should your debit fall on a public holiday or over a weekend, we will debit you on the previous weekday. Your Liberty account number will be mailed to you and will reflect on your bank statement as a reference for the debit order. \n\nPlease remember to keep us updated of any changes to your personal information.',
        thankYouIcon: {
            src: '/static/images/icons/lifewise-icon.jpg',
            width: '60px'
        }
    }
}