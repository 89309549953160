import {
  alpha,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/LogoSign';
import ThemeSwitch from 'src/components/ThemeSwitch/ThemeSwitch';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      {/* <ThemeSwitch /> */}
      <Logo width={150}/>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5'>Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}>
            <Box flex='1'>
              <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ fontWeight: 'bold' }}>Messaging Platform</Typography>
                <Typography variant='caption' sx={{ textTransform: 'none' }}>
                  {new Date().getTime()}
                </Typography>
              </Box>
              <Typography component='span' variant='body2' color='text.secondary'>
                {' '}
                new messages in your inbox
              </Typography>
            </Box>
            <Logo width={150}/>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
