
import {
  alpha, Box, styled,
  useTheme
} from '@mui/material';

import HeaderButtons from './Buttons';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background ?? '#111633', 0)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: center;
        width: 100%;
`,
);

function Header() {
  const theme = useTheme();

  return (
    <HeaderWrapper
    display='flex'
    alignItems='center'
    id="ins-funnel-header"
    >
      <Box display='flex' alignItems='center'>
        <HeaderButtons />
        <Box
          component='span'
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
