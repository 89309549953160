export const HeaderFooterTextMix = {
    footerText: '© 2024 MiX Telematics Africa (Pty) Ltd.',
    copyrightText: null
}

export const StepsMix = {
    minStep: 2,
    maxStep: 13,
    Qualify: {
        name: 'Qualify',
        title: 'Let’s get you started on your journey to ensuring the safety of yourself and your love ones. Please enter your details below:',
        inputName: {
            type: 'text',
            label: 'What is your first name?',
            placeholder: 'First Name​'
        },
        inputSurname: {
            type: 'text',
            label: 'What is your last name?',
            placeholder: 'Surname​'
        },
        inputPhone: {
            type: 'text',
            label: 'What is your phone number?',
            placeholder: 'Phone Number'
        },
        inputAgree : true
    },

    CoverSelect: {
        name: 'CoverSelect',
        title: 'Packages',
        text: 'Please select from one of our limited time offers below ',
        slider: [
            { title: 'Beame at only', premium: 'R59',descriptionArr: ['Comprehensive safety package:','Safety, recovery and tracking ability on your phone','One of the highest recovery rates in South Africa.','Real time crash detection and emergency response dispatch','Silent hi-jacking panic button','Geolock, never let your car move without consent','No installation fee'] },
            { title: 'MXV and Beame at only', premium: 'R139', descriptionArr: ['Safety, recovery and tracking ability on your phone','One of the highest recovery rates in South Africa.','No installation fee']  },
            { title: 'Comprehensive safety package', premium: 'R189', descriptionArr: ['Safety, recovery and tracking ability on your phone','One of the highest recovery rates in South Africa. ','Real time crash detection and emergency response dispatch','Silent hi-jacking panic button','Geolock, never let your car move without consent','No installation fee'] }
        ]
    },
    AdditionalCoverInfo : {
        name: 'AdditionalCoverInfo',
        title: 'Device fitment',
        text: 'Thank you for for selecting the #COVER# package from MiX Telematics, next step is to get your device fitted. Please enter your address:',
        inputFitmentLocation: {
            type: 'select',
            label: 'Would you like to to the install in-house or at another location?',
            placeholder: 'Destination',
            options: [
                {value: 'In-house', label: 'In-house', confirmationText: 'FC-x will be waiting for you at #FITMENT_LOCATION#'},
                {value: 'Another location', label: 'Another location', confirmationText: 'We will be sending FC- X to you at #FITMENT_LOCATION#'}
            ]
        },
        inputFitmentCentre: {
            type: 'select',
            label: 'Please select your nearest fitment centre',
            placeholder: 'Fitment centre',
            options: [
                {value: '68 Clarendon Dr, Discovery, Roodepoort, 1709', label: '68 Clarendon Dr, Discovery, Roodepoort, 1709', confirmationText: 'FC-x will be waiting for you at #FITMENT_LOCATION#'},
                {value: '36 Luipaard street, Krugersdorp, Gauteng', label: '36 Luipaard street, Krugersdorp, Gauteng', confirmationText: 'FC-x will be waiting for you at #FITMENT_LOCATION#'},
                {value: '25 Ampthill Ave., Benoni, Gauteng', label: '25 Ampthill Ave., Benoni, Gauteng', confirmationText: 'We will be sending FC- X to you at #FITMENT_LOCATION#'}
            ]
        },
        inputfitmentDate: {
            type: 'date',
            label: 'and a convenient fitment date',
            placeholder: 'Fitment date',
            confirmationText: ''
        },
        inputAddress: {
            type: 'date',
            label: 'Please indicate where we can do the installation',
            placeholder: 'Address',
            confirmationText: ''
        }
    },
    Summary: {
        name: 'Summary',
        
    },
    BankDetails: {
        name: 'BankDetails',
        title: 'Confirmation',
        text: 'Super. Now all we need are your banking details, and you will be covered!',
        inputDebitDate: {
            label: 'Debit Order Date'
        }
    },
    ThankYou: {
        standard: true,
        text: 'Your premium of #PREMIUM# pvpm for the Beame/Matrix service will be deducted on the #DEDUCTION_DATE# from your #BANK_NAME# bank account as well as your on-going premium. If we do not collect your monthly contribution, we may debit you on another date using a bank tracking facility. If you miss a payment, we may debit you the following month. Should your debit fall on a public holiday or over a weekend, we will debit you on the previous weekday. Your MIXT account number will be mailed to you and will reflect on your bank statement as a reference for the debit order.',
        secondaryText: 'Please remember to keep us updated of any changes to your personal information.'
    },
    ThankYouStatic: {
        standard: true,
        text: 'Thanks for contacting us! We will get in touch with you shortly. Ts & Cs Apply.',
        secondaryText: 'By submitting the form, you agree to send your information to Matrix to use according to our Privacy Policy.'
    },

}

export const MixStepper = {
    mapper: {
        '2': '1',
        '4': '2',
        '5': '3',
        '7': '4',
        '8': '5',
    },
    count: 6
}

