import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { selectTheme, setTheme } from '../store/theme/themeSlice';
import { themeNames } from 'src/store/theme/themeSlice.contracts';

export const ThemeContext = React.createContext((themeName: string): void => { });

interface IThemeProviderWrapper {
  children: ReactNode;
}

const ThemeProviderWrapper: React.FC<IThemeProviderWrapper> = (props: any) => {

  const dispatch = useAppDispatch();
  const curThemeName = useAppSelector(selectTheme);
  const setThemeName = (themeName: string): void => {
    switch (window.location.href) {
      case 'https://chubbtest.revx.ai/':
        dispatch(setTheme({ theme: themeNames.chubb }));
        break;
      case themeNames.hollard:
        dispatch(setTheme({ theme: themeNames.hollard }));
        break;
      case 'https://lifewisetest.revx.ai/':
        dispatch(setTheme({ theme: themeNames.lifeWise }));
        break;
      case 'https://mixtest.revx.ai/':
        dispatch(setTheme({ theme: themeNames.mix }));
        break;
      case 'https://liberty.revx.ai/':
        dispatch(setTheme({ theme: themeNames.liberty }));
        break;
      default:
        // NOTE: set the theme you are working on here
        dispatch(setTheme({ theme: themeNames.mix }));
        break
    }
  };

  useEffect(() => {
    setThemeName(curThemeName);
  }, [curThemeName]);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={themeCreator(curThemeName)}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
