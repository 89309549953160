export const HeaderFooterTextChubb = {
    footerText: 'PT Chubb General Insurance Indonesia and PT Chubb Life Insurance Indonesia are licensed and supervised by Otoritas Jasa Keuangan.',
    copyrightText: '© 2022 Chubb'
}

export const StepsChubb = {
    minStep: 1,
    maxStep: 12,
    Preamble: {
        name: 'Preamble',
        title: 'Thank you for choosing Chubb Insurance',
        text: 'Let’s sign you up for our comprehensive dental cover. ​Ready? Let’s get straight to it.',
        inputTC: {
            type: 'checkbox',
            label: 'Please confirm that you would like to take out a Chubb dental policy'
        }
    },
    Qualify: {
        name: 'Qualify',
        title: 'Excellent!​ Let’s start by getting to know you a bit better.',
        inputName: {
            type: 'text',
            label: 'What is your full name?',
            placeholder: 'Please enter your details as on your FIN​'
        },
        inputSurname: {
            type: 'text',
            label: 'What is your surname?',
            placeholder: 'Please enter your details as on your FIN​'
        },
        inputPhone: {
            type: 'text',
            label: 'What is your phone number?',
            placeholder: 'E.g. 8346 2465​'
        },

    },
    PersonalDetails: {
        name: 'PersonalDetails',
        title: 'In accordance with Singaporean law we are required to verify the following information. Your details are safe and private with us. ',
        inputIDNumber: {
            label: 'What is your FIN/SG Pass number?',
            placeholder: 'Please enter you FIN/SG Pass number​'
        },
        inputEmail: {
            label: 'What is your email address?',
            placeholder: 'Please enter email here​'
        }

    },
    Summary: {
        name: 'Summary',
        title: 'Confirmation',
        text: 'You are signing up for a Chubb dental policy that would cover you for: S$2 000 for only S$39 per month. Are you happy to proceed?'
    },
    BankDetails: {
        name: 'BankDetails',
        title: 'Confirmation',
        text: 'Super. Now all we need are your banking details, and you will be covered!',
        inputDebitDate: {
            label: 'Giro Date'
        },
    },
    OTPStep1: {
        name: 'OTPStep1',
        title: 'A sms with an OTP has been sent to your cellphone number',
        text: 'By capturing the OTP, you agree that the OTP will be your electronic signature to accept the Chubb dental Plan/Policy and authorization for a Monthly Giro deduction and be a representation of your signature for all purposes, including legally binding You to this Membership Agreement.'
    },
    OTPStep2: {
        name: 'OTPStep2',
        title: 'Enter OTP:',
        text: 'Chubb dental Plan/Policy'
    },
    ThankYou: {
        name: 'ThankYou',
        title: 'Welcome to the Chubb family!',
        email: 'memberadmin@chubb.com.sg',
        tcLink: 'visit www.chubb.com.sg',
        additions: 'add a partner'
    },

}